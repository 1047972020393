<template>
    <div class="ai-tool-border py-2 mt-2">
        <div class="d-flex justify-content-between ai-tool-heading  px-1" v-b-toggle="id">
            <span class="">
                <slot name="title">Name</slot>
            </span>
            <span class="when-opened-is">
                <i class="fa-solid fa-angle-up"></i>
            </span>
            <span class="when-closed-is">
                <i class="fa-solid fa-angle-down"></i>
            </span>

        </div>

        <b-collapse :id="id" :accordion="id">
            <slot name="collapse"></slot>
        </b-collapse>
    </div>
</template>

<script>
export default {
props:{
    id:{
        type:String,
        default:'collapse'
    }
}
}
</script>
<style >
.collapsed > .when-opened-is,
:not(.collapsed) > .when-closed-is {
  display: none;
}
.ai-tool-border{
    /* border-radius: 6px; */
border-top: 0.8px solid #D2D8E0;
border-bottom: 0.8px solid #D2D8E0;
padding: 4px;
background: #FFF;
}

.ai-tool-heading{
    color: #18191C;

font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.28px;
}
</style>