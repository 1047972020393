<template>
  <div class="">
    <div class="row" style="background: #f5f6f6; padding: 0px 10px">
      <div class="col-12 pl-0 col-md-5 py-3">
        <div
          class="card"
          style="
            border: 1px solid transparent;
            border-radius: 8px;
            height: 420px;
            overflow: auto;
          "
        >
          <div class="card-body">
            <div class="quizell-step3-sidebar-title">Design Customization</div>
            <div class="quizell-step3-sidebar-description">
              Choose a parameters that align with your brand or the mood of the
              quiz.
            </div>
            <div class="quizell-ai-tools mt-3">
              <div class="">
                <div class="d-flex">
                  <div class="mr-2">
                    <svg
                      width="20"
                      height="21"
                      viewBox="0 0 20 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M14.1667 7.16667H16.6667V17.1667H17.5V18H14.1667V17.1667H15V14.6667H11.6667L10.4167 17.1667H11.6667V18H8.33333V17.1667H9.16667L14.1667 7.16667ZM15 8L12.0833 13.8333H15V8ZM4.16667 3H8.33333C9.25833 3 10 3.74167 10 4.66667V13.8333H7.5V9.66667H5V13.8333H2.5V4.66667C2.5 3.74167 3.24167 3 4.16667 3ZM5 4.66667V8H7.5V4.66667H5Z"
                        fill="#18191C"
                      />
                    </svg>
                  </div>
                  <div class="ai-tool-heading">Font</div>
                </div>
                <select
                  name=""
                  v-model="QuizPayload.design.font"
                  class="font-select"
                  id=""
                >
                  <option
                    v-for="font in getFontFamilyList"
                    :key="font.value"
                    :value="font.value"
                  >
                    {{ font.value }}
                  </option>
                </select>
              </div>

              <!-- Colors -->
              <Collapse id="colors">
                <template v-slot:title>
                  <span class="mr-2"
                    ><svg
                      width="20"
                      height="21"
                      viewBox="0 0 20 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.76667 16.3333L4.16667 14.7333L10.8833 8.00001L12.5 9.61667M17.2583 5.19167L15.3083 3.24167C15 2.91667 14.4583 2.91667 14.1333 3.24167L11.5333 5.84167L9.925 4.25001L8.75 5.42501L9.93333 6.60834L2.5 14.0417V18H6.45833L13.8917 10.5667L15.075 11.75L16.25 10.575L14.65 8.97501L17.25 6.37501C17.5833 6.04167 17.5833 5.50001 17.2583 5.19167Z"
                        fill="#18191C"
                      />
                    </svg>
                  </span>
                  Colors
                </template>
                <template v-slot:collapse>
                  <div class="">
                    <div class="d-flex justify-content-between">
                      <div class="">
                        <div class="ai-tool-heading" style="color: #535355">
                          Background
                        </div>
                        <div class="d-flex">
                          <input
                            v-model="QuizPayload.design.background_color"
                            type="color"
                            value="#FFFFFF"
                            class="ai-color-circle mr-2"
                          />
                          <input
                            v-model="QuizPayload.design.background_color"
                            class="ai-tool-heading ai-color-input"
                            value="#FFFFFF"
                          />
                        </div>
                      </div>

                      <div class="">
                        <div class="ai-tool-heading" style="color: #535355">
                          Primary Color
                        </div>
                        <div class="d-flex">
                          <input
                            v-model="QuizPayload.design.primary_color"
                            type="color"
                            value="#193247"
                            class="ai-color-circle mr-2"
                          />
                          <input
                            v-model="QuizPayload.design.primary_color"
                            class="ai-tool-heading ai-color-input"
                            value="#193247"
                          />
                        </div>
                      </div>
                    </div>

                    <div
                      class="d-flex justify-content-between"
                      style="color: #535355"
                    >
                      <div class="">
                        <div class="ai-tool-heading">Secondary Color</div>
                        <div class="d-flex">
                          <input
                            v-model="QuizPayload.design.secondary_color"
                            type="color"
                            value="#FFFFFF"
                            class="ai-color-circle mr-2"
                          />
                          <input
                            v-model="QuizPayload.design.secondary_color"
                            class="ai-tool-heading ai-color-input"
                            value="#FFFFFF"
                          />
                        </div>
                      </div>

                      <div class="">
                        <div class="ai-tool-heading" style="color: #535355">
                          Text Color
                        </div>
                        <div class="d-flex">
                          <input
                            type="color"
                            v-model="QuizPayload.design.text_color"
                            value="#193247"
                            class="ai-color-circle mr-2"
                          />
                          <input
                            v-model="QuizPayload.design.text_color"
                            class="ai-tool-heading ai-color-input"
                            value="#193247"
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      class="d-flex justify-content-between"
                      style="color: #535355"
                    >
                      <div class="">
                        <div class="ai-tool-heading" style="color: #535355">
                          Button Text Color
                        </div>
                        <div class="d-flex">
                          <input
                            type="color"
                            v-model="QuizPayload.design.button_text_color"
                            value="#767575"
                            class="ai-color-circle mr-2"
                          />
                          <input
                            v-model="QuizPayload.design.button_text_color"
                            class="ai-tool-heading ai-color-input"
                            value="#193247"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </Collapse>

              <!-- image -->
              <Collapse id="image">
                <template v-slot:title>
                  <span class="mr-2">
                    <svg
                      width="20"
                      height="21"
                      viewBox="0 0 20 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M15.8333 16.3333H4.16667V4.66667H15.8333M15.8333 3H4.16667C3.72464 3 3.30072 3.17559 2.98816 3.48816C2.67559 3.80072 2.5 4.22464 2.5 4.66667V16.3333C2.5 16.7754 2.67559 17.1993 2.98816 17.5118C3.30072 17.8244 3.72464 18 4.16667 18H15.8333C16.2754 18 16.6993 17.8244 17.0118 17.5118C17.3244 17.1993 17.5 16.7754 17.5 16.3333V4.66667C17.5 4.22464 17.3244 3.80072 17.0118 3.48816C16.6993 3.17559 16.2754 3 15.8333 3ZM11.6333 10.7417L9.34167 13.6917L7.70833 11.725L5.41667 14.6667H14.5833L11.6333 10.7417Z"
                        fill="#18191C"
                      />
                    </svg>
                  </span>
                  Image
                </template>

                <template v-slot:collapse>
                  <div
                    v-if="selectedImageURL"
                    class="d-flex my-3"
                    style="gap: 10px; position: relative"
                  >
                    <div class="ai-upload-image">
                      <div class="ai-upload-image-cross" @click="clearImage">
                        x
                      </div>
                      <lazy-image
                        style="
                          width: 88px;
                          object-fit: cover;
                          border-radius: 6px;
                        "
                        :src="selectedImageURL"
                      ></lazy-image>
                    </div>
                  </div>
                  <div class="">
                    <UploadFile
                      @files="updatedFile"
                      accept="image/*"
                      name="Image"
                    ></UploadFile>
                  </div>
                </template>
              </Collapse>
              <!-- Language -->
              <collapse id="language">
                <template v-slot:title>
                  <span class="mr-2">
                    <svg
                      width="20"
                      height="21"
                      viewBox="0 0 20 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.725 13.0583L8.60831 10.9666L8.63331 10.9416C10.0833 9.32496 11.1166 7.46663 11.725 5.49996H14.1666V3.83329H8.33331V2.16663H6.66665V3.83329H0.833313V5.49996H10.1416C9.58331 7.09996 8.69998 8.62496 7.49998 9.95829C6.72498 9.09996 6.08331 8.15829 5.57498 7.16663H3.90831C4.51665 8.52496 5.34998 9.80829 6.39165 10.9666L2.14998 15.15L3.33331 16.3333L7.49998 12.1666L10.0916 14.7583L10.725 13.0583ZM15.4166 8.83329H13.75L9.99998 18.8333H11.6666L12.6 16.3333H16.5583L17.5 18.8333H19.1666L15.4166 8.83329ZM13.2333 14.6666L14.5833 11.0583L15.9333 14.6666H13.2333Z"
                        fill="#18191C"
                      />
                    </svg>
                  </span>
                  Language
                </template>
                <template v-slot:collapse>
                  <ul class="p-0 m-0">
                    <li
                      class="d-flex px-1 align-items-center py-2"
                      :class="[
                        QuizPayload.design.language == language.lang
                          ? 'design-active'
                          : '',
                      ]"
                      @click="QuizPayload.design.language = language.lang"
                      v-for="(language, index) in languages"
                      :key="index"
                    >
                      <span class="mr-2">
                        <lazy-image
                          style="height: 30px; width: 30px"
                          :src="language.flag"
                        ></lazy-image>
                      </span>
                      {{ language.langText }}
                    </li>
                  </ul>
                </template>
              </collapse>
              <!-- Currency -->
              <collapse id="currency">
                <template v-slot:title>
                  <span class="mr-2">
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.8335 12.5H7.50016C7.50016 13.4 8.64183 14.1667 10.0002 14.1667C11.3585 14.1667 12.5002 13.4 12.5002 12.5C12.5002 11.5833 11.6335 11.25 9.80016 10.8083C8.0335 10.3667 5.8335 9.81667 5.8335 7.5C5.8335 6.00833 7.0585 4.74167 8.75016 4.31667V2.5H11.2502V4.31667C12.9418 4.74167 14.1668 6.00833 14.1668 7.5H12.5002C12.5002 6.6 11.3585 5.83333 10.0002 5.83333C8.64183 5.83333 7.50016 6.6 7.50016 7.5C7.50016 8.41667 8.36683 8.75 10.2002 9.19167C11.9668 9.63333 14.1668 10.1833 14.1668 12.5C14.1668 13.9917 12.9418 15.2583 11.2502 15.6833V17.5H8.75016V15.6833C7.0585 15.2583 5.8335 13.9917 5.8335 12.5Z"
                        fill="#18191C"
                      ></path>
                    </svg>
                  </span>
                  Currency
                </template>
                <template v-slot:collapse>
                  <ul class="p-0 m-0">
                    <li
                      class="d-flex px-1 align-items-center py-2"
                      :class="[
                        QuizPayload.design.currency == currency.id
                          ? 'design-active'
                          : '',
                      ]"
                      @click="QuizPayload.design.currency = currency.id"
                      v-for="(currency, index) in currencies"
                      :key="index"
                    >
                      <span class="mr-2">
                        <lazy-image
                          style="height: 30px; width: 30px"
                          :src="currency.flag"
                        ></lazy-image>
                      </span>
                      {{ currency.country }} - {{ currency.sign }}
                    </li>
                  </ul>
                </template>
              </collapse>
            </div>
          </div>
        </div>
      </div>
      <div
        class="col-12 pl-0 col-md-7 py-3"
        :style="{ 'font-family': QuizPayload.design.font }"
      >
        <div
          class="card"
          style="
            border: 1px solid transparent;
            border-radius: 8px;
            height: 420px;
            overflow: auto;
          "
          :style="{ background: QuizPayload.design.background_color }"
        >
          <div class="card-body">
            <div class="row h-100">
              <div :class="[selectedImageURL ? 'col-6' : 'col-12']">
                <div class="d-flex h-100 justify-content-center flex-column">
                  <div class="">
                    <div
                      class="ai-preview-heading"
                      :style="{ color: QuizPayload.design.text_color }"
                    >
                      The main title text
                    </div>
                    <div
                      class="ai-preview-description py-3"
                      :style="{ color: QuizPayload.design.text_color }"
                    >
                      Take our quiz about skincare products and discover the
                      best products tailored specifically for your skin's unique
                      needs.
                    </div>
                    <button
                      :style="{
                        background: QuizPayload.design.primary_color,
                        color: QuizPayload.design.button_text_color,
                      }"
                      class="ai-preview-button"
                    >
                      Button
                    </button>
                  </div>
                </div>
              </div>
              <div class="col-6" v-if="selectedImageURL">
                <lazy-image
                  ref="image"
                  style="object-fit: cover; border-radius: 6px"
                  class="w-100 h-100"
                  :src="selectedImageURL"
                ></lazy-image>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-12 d-flex justify-content-end">
        <Button @click="$emit('click')"
          >Next Step
          <span class="mr-1"
            ><svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M3.33334 9.16669V10.8334H13.3333L8.75001 15.4167L9.93334 16.6L16.5333 10L9.93334 3.40002L8.75001 4.58336L13.3333 9.16669H3.33334Z"
                fill="white"
              /></svg
          ></span>
        </Button>
      </div>
    </div>
  </div>
</template>
<script>
import fontFamilyList from "../../../customize/staticPages/fontFamilyList";
import LazyImage from "../../../../components/LazyImage.vue";
import Collapse from "./LayoutComponents/Collapse.vue";
import UploadFile from "./LayoutComponents/UploadFile.vue";
import Button from "../../Layout/Button.vue";
import axios from "axios";
export default {
  components: { Button, Collapse, LazyImage, UploadFile },
  props: { QuizPayload: Object },
  data() {
    return {
      imageUrl: this.QuizPayload.design.image
        ? URL.createObjectURL(this.QuizPayload.design.image)
        : "",
      languages: [],
      currencies: [],
    };
  },
  computed: {
    selectedImageURL() {
      return this.imageUrl;
    },
    getFontFamilyList() {
return fontFamilyList;
},
  },
  methods: {
    async getCurrencyLanguage() {
      await axios
        .get("/quiz/design-customization")
        .then((response) => {
          this.currencies = response.data.data.currencies;
          this.languages = response.data.data.languages;
        })
        .catch([]);
    },
    clearImage() {
      this.QuizPayload.design.image = "";
      this.imageUrl = null;
    },
    updatedFile(files) {
      if (files[0]) {
        const isValid = this.isValidImageFormat(files[0].type);

        if (!isValid) {
          this.$toasted.show(
            "Invalid file format. Please select a JPEG, JPG, PNG, or GIF file.",
            {
              theme: "toasted-primary",
              position: "bottom-center",
              duration: 2000,
            }
          );

          return;
        }
        let th = this;
        th.QuizPayload.design.image = files[0];
        const reader = new FileReader();
        reader.onload = function (e) {
          th.imageUrl = null;
          th.$nextTick(() => {
            th.imageUrl = e.target.result;

            // this.imageUrl = e.target.result;
          });
          // th.imageUrl =e.target.result;
        };
        reader.readAsDataURL(files[0]);
      }
    },
    isValidImageFormat(fileType) {
      const validTypes = ["image/jpeg", "image/jpg", "image/png", "image/gif"];
      return validTypes.includes(fileType);
    },
  },
  mounted() {
    this.getCurrencyLanguage();
  },
};
</script>
<style scoped>
.design-active {
  background: rgba(77, 27, 126, 0.3);
  border-radius: 4px;
  cursor: pointer;
}
ul li:not(.design-active):hover {
  background: #f5f6f6;
  border-radius: 4px;
  cursor: pointer;
}

.ai-preview-heading {
  color: #193247;

  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 28px */
  letter-spacing: 0.4px;
}
.ai-preview-description {
  color: #687b8b;

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.28px;
}
.ai-preview-button {
  padding: 8px 16px;

  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.28px;
  border-radius: 6.822px;
  background: #c8c7c6;
  border: none;
}

.ai-upload-image-cross {
  position: absolute;
  right: -2px;
  top: -5px;
  background: red;
  border-radius: 50%;
  padding: 7px;
  /* line-height: 0.8; */
  height: 13px;
  width: 10px;
  font-size: 12px;
  display: none;
  justify-content: center;
  align-items: center;
  color: wheat;
  font-weight: 600;
  cursor: pointer;
}
.ai-upload-image {
  position: relative;
}
.ai-upload-image:hover .ai-upload-image-cross {
  display: flex;
}
input[type="color"] {
  width: 25px;
  height: 23px;
  border-radius: 50%;
  overflow: hidden;
  border: none;
}

input[type="color"]::-webkit-color-swatch {
  border: none;
  border-radius: 50%;
  width: 25px;
  height: 23px;
  padding: 0;
}

input[type="color"]::-webkit-color-swatch-wrapper {
  border: none;
  width: 25px;
  height: 23px;
  border-radius: 50%;
  padding: 0;
}

.ai-color-input {
  width: 50%;
  color: #535355;
  border: none;
}

.ai-color-input:focus-visible {
  border: none;
  outline: none;
}

.font-select:active,
.font-select:focus-visible {
  outline: 1px solid rgba(77, 27, 126, 1);
}
.font-select {
  width: 100%;
  border-radius: 6px;
  border: 0.8px solid #d2d8e0;
  background: #fff;
  padding: 4px;
  color: #535355;

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.56px;
}

.ai-tool-heading {
  color: #18191c;

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.28px;
}

.quizell-step3-sidebar-title {
  color: #1f1f28;

  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  /* 22.4px */
  letter-spacing: 0.64px;
}

.quizell-step3-sidebar-description {
  color: #73738d;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
</style>
